import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { getSafe } from '../utils/getSafe';
import Loading from '../components/Loading/Loading';
import ComicPage from '../components/ComicPage/ComicPage';

const Comics = ({ data }) => {
    return (
        <Layout className="comics-page">
            <Loading />
            <ComicPage />
        </Layout>
    );
};

export default Comics;
