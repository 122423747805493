import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import NavBar from '../NavBar/NavBar';
import SEO from '../SEO/SEO';
import Hero from '../Hero/Hero';
import Title from '../Title/Title';
import VideoThumbnail from '../VideoThumbnail/VideoThumbnail';
import { Pagination } from '../../utils/pagination';
import { getSafe } from '../../utils/getSafe';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import { fetchPrismicResourcesComics, findGetParameter } from '../../utils/prismic';
import { SelectOptions, SelectStyles, DropdownIndicator } from '../../utils/orderBy';
import Select from 'react-select';
import '../../stylesheets/components/ordering.scss';

const ComicPage = () => {
    const [selectValue, setSelectValue] = useState({ value: 'desc', label: 'NEWEST > OLDEST' });
    const [comics, setComics] = useState([]);
    const [pageInfo, setPageInfo] = useState({ pageCount: 1 });
    const [currentPage, setCurrentPage] = useState(findGetParameter('p'));
    const [displayPagination, setDisplayPagination] = useState(true);

    useEffect(() => {
        let isMounted = true;
        getPrismicResourcesComics(isMounted, currentPage, selectValue.value);
        return () => {
            isMounted = false;
        };
    }, [currentPage]);

    const getPrismicResourcesComics = (isMounted, currentPage, ordering = 'desc') => {
        setDisplayPagination(false);
        setComics([]);
        fetchPrismicResourcesComics(currentPage, ordering).then((res) => {
            if (res && res.data && res.data.length && isMounted) {
                setPageInfo({ pageCount: res.total_pages });
                setComics(res.data);
                setDisplayPagination(true);
            }
        });
    };

    const handlerSelect = (event) => {
        setCurrentPage(1);
        getPrismicResourcesComics(true, 1, event.value);
    };
    const title = { text: 'Comics' };
    return (
        <>
            <SEO title={title} type="WebPage" urlPath="/comics" />
            <Hero
                leftImage="garfield45/home-garfield-cake.png"
                rightImage="garfield45/home-garfield-45th-mobile.png"
                rightImageMobile="garfield45/home-garfield-45th-mobile.png"
                classNameExternal="hero-videos"
            />
            <NavBar active="comics" />
            <Title fileName="comics-title.png" className="page-title" alt="Comics Title" />
            <div className="container comic-ordering">
                <label>Order by</label>
                <Select
                    options={SelectOptions()}
                    value={selectValue}
                    onChange={(value) => {
                        setSelectValue(value);
                        handlerSelect(value);
                    }}
                    components={{ DropdownIndicator }}
                    className="ordering-select"
                    classNamePrefix="react-select"
                    styles={SelectStyles()}
                    isSearchable={false}
                />
            </div>
            <div className="container videos-container">
                <div className="columns is-flex-wrap-wrap">
                    {comics &&
                        comics.map((comic, i) => {
                            const data = getSafe(() => comic.data, {});
                            const description = getSafe(() => data.description[0].text, null);
                            const title = getSafe(() => data.title[0].text, null);
                            let url = getSafe(() => comic.uid, null);
                            if (url) {
                                url = '/comics/' + url;
                            }
                            const image = data.image.url;

                            return (
                                <div className="column is-12-mobile is-6-tablet is-4-desktop" key={i}>
                                    <ScrollRevealAnimation
                                        animation="fadeIn"
                                        className=""
                                        delay={i * 0.25}
                                        offset="50px"
                                    >
                                        <VideoThumbnail
                                            title={title}
                                            thumbnail={image}
                                            description={description}
                                            playIconClass="comic-button"
                                            url={url}
                                        />
                                    </ScrollRevealAnimation>
                                </div>
                            );
                        })}
                </div>
            </div>
            <Pagination
                pageInfo={pageInfo}
                slug="comics"
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                displayPagination={displayPagination}
            />
        </>
    );
};

ComicPage.propTypes = {};

const mapStateToProps = ({ blocked }) => {
    return { blocked };
};

export default connect(mapStateToProps, null)(ComicPage);
